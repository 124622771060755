// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-communities-js": () => import("../src/pages/communities.js" /* webpackChunkName: "component---src-pages-communities-js" */),
  "component---src-pages-financial-services-js": () => import("../src/pages/financial-services.js" /* webpackChunkName: "component---src-pages-financial-services-js" */),
  "component---src-pages-government-js": () => import("../src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-healthcare-js": () => import("../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuals-js": () => import("../src/pages/individuals.js" /* webpackChunkName: "component---src-pages-individuals-js" */),
  "component---src-pages-organizations-js": () => import("../src/pages/organizations.js" /* webpackChunkName: "component---src-pages-organizations-js" */),
  "component---src-pages-partials-ccl-stats-js": () => import("../src/pages/partials/cclStats.js" /* webpackChunkName: "component---src-pages-partials-ccl-stats-js" */),
  "component---src-pages-partials-ccl-trends-js": () => import("../src/pages/partials/cclTrends.js" /* webpackChunkName: "component---src-pages-partials-ccl-trends-js" */),
  "component---src-pages-partials-community-stats-js": () => import("../src/pages/partials/communityStats.js" /* webpackChunkName: "component---src-pages-partials-community-stats-js" */),
  "component---src-pages-partials-financial-services-stats-js": () => import("../src/pages/partials/financialServicesStats.js" /* webpackChunkName: "component---src-pages-partials-financial-services-stats-js" */),
  "component---src-pages-partials-government-stats-js": () => import("../src/pages/partials/governmentStats.js" /* webpackChunkName: "component---src-pages-partials-government-stats-js" */),
  "component---src-pages-partials-healthcare-stats-js": () => import("../src/pages/partials/healthcareStats.js" /* webpackChunkName: "component---src-pages-partials-healthcare-stats-js" */),
  "component---src-pages-partials-home-key-areas-js": () => import("../src/pages/partials/homeKeyAreas.js" /* webpackChunkName: "component---src-pages-partials-home-key-areas-js" */),
  "component---src-pages-partials-home-leadspace-js": () => import("../src/pages/partials/homeLeadspace.js" /* webpackChunkName: "component---src-pages-partials-home-leadspace-js" */),
  "component---src-pages-partials-individuals-stats-js": () => import("../src/pages/partials/individualsStats.js" /* webpackChunkName: "component---src-pages-partials-individuals-stats-js" */),
  "component---src-pages-partials-org-stats-js": () => import("../src/pages/partials/orgStats.js" /* webpackChunkName: "component---src-pages-partials-org-stats-js" */),
  "component---src-pages-partials-trend-report-js": () => import("../src/pages/partials/trendReport.js" /* webpackChunkName: "component---src-pages-partials-trend-report-js" */)
}

